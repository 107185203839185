/*==========================================================================
All Side Bar
========================================================================== */
.all__sidebar {
    &-item {
        border: 1px solid var(--border-color-2);
        padding: 30px;
        margin-bottom: 30px;
        h5 {
            position: relative;
            padding-bottom: 10px;
            margin-bottom: 30px;
            &::after {
                position: absolute;
                content: '';
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                width: 60px;
                background: var(--primary-color);
            }
        }
        &:last-child {
            margin: 0;
        }
        &-category {
            ul {
                padding: 0;
                margin: 0;
                li {
                    list-style: none;
                    a {
                        display: block;
                        color: var(--heading-color);
                        font-family: var(--body-font);
                        transition: all 0.4s ease-out 0s;
                        margin-bottom: 10px;
                        padding-bottom: 14px;
                        font-weight: 400;
                        border-bottom: 1px solid var(--border-color-2);
                        i {
                            margin-right: 5px;
                        }
                        span {
                            float: right;
                            position: relative;
                            top: 2px;
                        }
                        &:hover {
                            color: var(--primary-color);
                            margin-left: 5px;
                        }
                    }
                    &:last-child a {
                        margin-bottom: 0;
                    }
                    .active {
                        color: var(--primary-color);
                        margin-left: 5px;                        
                    }
                }
            }
        }
        &-price {
            ul {
                padding: 0;
                margin: 0;
                li {
                    list-style: none;
                    margin-bottom: 10px;
                    &:last-child {
                        margin: 0;
                    }
                    i {
                        color: var(--primary-color);
                        margin-right: 10px;
                    }
                }
            }
            h4 {
                margin: 25px 0;
                span {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 25px;    
                    color: var(--color-1);                
                }
            }              
        }
        &-booking {
            &-item {
                & .nice-select {
                    color: var(--heading-color);
                    border: 1px solid var(--border-color-2);
                    border-radius: 0;
                    padding: 0 15px;
                    background: transparent;
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    float: initial;
                    font-size: 15px;
                    &::after {
                        width: 9px;
                        height: 9px;
                        right: 18px;
                        margin-top: -5px;
                        border-bottom: 1px solid var(--color-1);
                        border-right: 1px solid var(--color-1);
                        transition: all 0.4s ease-out 0s;                    
                    }
                    .list {
                        margin: 0;
                        border-radius: 0;
                        width: 100%;
                        transition: all 0.4s ease-out 0s;
                        li {
                            color: var(--heading-color);
                        }
                        .option.selected.focus {
                            color: var(--primary-color);
                            font-weight: 500;
                        }
                    }
                }
            }           
        }
        &-search {
            form {
                position: relative;
                input {
                    background: transparent;
                    height: 70px;
                }
                button {
                    background: transparent;
                    position: absolute;
                    right: 0;
                    top: 0;
                    color: var(--primary-color);
                    bottom: 0;                    
                    display: inline-block;
                }
            }
        }
        &-post {
            &-item {
                display: flex;
                align-items: center;
                gap: 20px;
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid var(--border-color-2);
                &-image {
                    width: 80px;
                    height: 80px;
                    min-width: 80px;
                    overflow: hidden;
                    img {
                        transform: scale(1);  
                        transition: all 0.4s ease-out 0s;
                        object-fit: cover;
                    }
                    &:hover img {
                        transform: scale(1.04);                  
                    }
                }
                &-content {
                    span {
                        font-size: 12px;
                        line-height: 22px;
                        font-weight: 600;
                        font-family: var(--heading-font);
                        color: var(--color-1);
                        i {
                            color: var(--primary-color);
                            margin-right: 8px;
                            font-size: 14px;
                        }
                    }
                    h6 {
                        a {
                            transition: all 0.4s ease-out 0s;
                            display: inline-block;
                            &:hover {
                                color: var(--primary-color);
                            }
                        }                        
                    }
                }
                &:last-child {
                    margin: 0;
                    padding: 0;
                    border: 0;
                }
            }
        }
        &-tag {
            ul {
                padding: 0;
                margin: 0;
                margin-left: -5px;
                margin-top: -5px;
                li {
                    display: inline-block;
                    list-style: none;
                    a {
                        transition: all 0.4s ease-out 0s;
                        border: 1px solid var(--border-color-2);
                        margin: 5px;
                        padding: 5px 20px 8px 20px;
                        display: inline-block;
                        &:hover {
                            color: var(--white);
                            background: var(--primary-color);
                            border-color: var(--primary-color);
                        }                         
                    }
                }
            }
        }
        &-help {            
            position: relative;
            z-index: 1;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 50px 40px;
            text-align: center;
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                bottom: 0;
                z-index: -1;
                background: var(--primary-color);              
                opacity: 0.8;
            }  
            &-icon {
                margin-bottom: 35px;
                i {
                    width: 90px;
                    height: 90px;
                    line-height: 90px;
                    display: inline-block;
                    font-size: 30px;
                    text-align: center;
                    border-radius: 50%;
                    background: var(--white);
                    color: var(--primary-color);
                }
            }
            h5 {
                color: var(--white);
                margin-bottom: 15px;
            }          
            &-contact {
                &-content {
                    span {
                        color: var(--white);
                        font-weight: 500;
                        font-family: var(--heading-font);
                        display: inline-block;
                    }
                    h6 {
                        color: var(--white);
                    }
                }
            }
        }
    }
}