:root {
    --body-font: 'Lato', sans-serif;
    --heading-font: 'Bai Jamjuree', sans-serif;
    --white: #FFFFFF;
    --black: #000000;
    --primary-color: #B89146;
    --heading-color: #0E1317;
    --body-color: #333334;
    --color-1: #666667;
    --color-2: #999999;
    --color-3: #CCCCCC;
    --color-4: #F7F7F7;
    --color-5: #EDECE7;
    --color-6: #141C21;
    --color-7: #262B2E;
    --color-8: #3E4245;
    --color-9: #8A8A8A;
    --color-10: #D9D9D9;
    --color-11: #E0E0E1;
    --color-12: #CFD0D1;    
    --border-color-1: #373939;
    --border-color-2: #E7E7E8;
    --border-color-3: #E7E8E9;
    --box-shadow-1: 0px 10px 50px rgba(0, 0, 0, 0.06);
    --box-shadow-2: 0px 10px 50px rgba(0, 0, 0, 0.05);




    --color-13: #0E1111;
    --border-color-55: #DEDFDF;
    --border-color-5: #282A2A;
    --box-shadow-3: 0px 7px 50px rgba(0, 0, 0, 0.07);
    --box-shadow-4: 0px 10px 50px rgba(0, 0, 0, 0.05);
}