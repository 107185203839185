/*==========================================================================
Menu Bar Sticky
==========================================================================*/
.header__sticky {
    &-sticky-menu {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 999;
        width: 100%;
        box-shadow: var(--box-shadow-3);
        animation: header_sticky 1.1s;
        background: var(--white);
        display: block;
    }
    @keyframes header_sticky {
        0% {
            top: -250px;
        }
        100% {
            top: 0;
        }
    }
}
.header__sticky.header__sticky-sticky-menu {
    & .header__area {
        background: var(--heading-color);
    }
}
/*==========================================================================
Menu Bar
==========================================================================*/
.header__area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99999;
    border-bottom: 1px solid var(--border-color-1);
    padding: 0 50px;
    &-menubar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.three {
            background: var(--white);
            padding: 0 35px;
        }
        &-left {
            &-logo {
                a {
                    display: inline-block;
                    img {
                        max-width: 150px;
                        position: relative;
                        z-index: 999;
                    }
                }
            }
        }
        &-right {
            &-menu {                
                ul {
                    padding: 0;
                    margin: 0;
                    li {
                        display: inline-block;
                        position: relative;
                        list-style: none;
                        &:hover > a {
                            color: var(--primary-color);
                        }
                        &:hover > .sub-menu {
                            top: 100%;
                            opacity: 1;
                            visibility: visible;
                        }
                        .sub-menu {
                            position: absolute;
                            background: var(--white);
                            min-width: 240px;
                            transition: all 0.3s ease-out 0s;
                            top: 120%;
                            opacity: 0;
                            box-shadow: var(--box-shadow-4);
                            visibility: hidden;
                            z-index: 99;
                            text-align: left;
                            li {
                                display: block;
                                margin: 0;
                                border-bottom: 1px solid var(--color-5);
                                a {
                                    color: var(--heading-color);
                                    padding: 12px 20px;
                                    transition: all 0.4s ease-out 0s;
                                    font-size: 15px;
                                    text-transform: capitalize;
                                    font-weight: 800;
                                    &::after {
                                        position: absolute;
                                        content: '';
                                        background: var(--primary-color);
                                        width: 2px;
                                        transition: all 0.5s ease-out 0s;
                                        height: 0;
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }
                                }
                                .sub-menu {
                                    left: 100%;
                                    top: -2px;
                                }
                                &:hover > a {
                                    color: var(--primary-color);
                                    padding-left: 25px;
                                    &::after {
                                        height: 100%;
                                    }
                                }
                            }
                            li:last-child{
                                border: none;
                            }
                            .menu-item-has-children {
                                a {
                                    &::before {
                                        right: 28px;
                                        background: var(--heading-color);
                                    }
                                    &::after {
                                        right: 25px;
                                        background: var(--heading-color);
                                    }
                                }
                            }
                        }
                        ul {
                            .sub-menu li .sub-menu{
                                color: var(--white);
                                cursor: pointer;
                            }
                        }
                        a {
                            color: var(--white);
                            display: block;
                            font-size: 18px;
                            line-height: 28px;
                            font-weight: 600;
                            transition: all 0.4s ease-out 0s;
                            text-transform: capitalize;      
                            padding: 46px 35px;   
                            font-family: var(--heading-font);              
                        }
                        &:last-child a {
                            padding-right: 0;
                        }
                        &.menu-item-has-children > {
                            a {
                                position: relative;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 52%;
                                    transform: translateY(-50%);
                                    right: 21px;
                                    width: 2px;
                                    height: 8px;
                                    background: var(--white);
                                    transition: all 0.3s ease;        
                                }
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 52%;
                                    transform: translateY(-50%);
                                    right: 18px;
                                    width: 8px;
                                    height: 2px;
                                    background: var(--white);    
                                }
                            }
                        }
                        &.menu-item-has-children {
                            &:hover > a {
                                &::before {
                                    transform: translateY(-50%) rotate(90deg);
                                    opacity: 0;  
                                }
                                &::after {
                                    background: var(--primary-color);
                                }
                            }
                        }
                    }
                }
                &.three {
                    ul {
                        li {
                            &:hover > a {
                                color: var(--primary-color);
                            }
                            ul {
                                .sub-menu li .sub-menu{
                                    color: var(--heading-color);
                                }
                            }
                            a {
                                color: var(--heading-color);    
                                padding: 36px 35px;        
                            }
                            &.menu-item-has-children > {
                                a {
                                    &::before {
                                        background: var(--heading-color);    
                                    }
                                    &::after {
                                        background: var(--heading-color);    
                                    }
                                }
                            }
                            &:last-child a {
                                padding-right: 0;
                            }
                            &.menu-item-has-children {
                                &:hover > a {
                                    &::after {
                                        background: var(--primary-color);
                                    }
                                }
                            }
                        }
                    }                    
                } 
            }
            &-box {
                display: flex;
                align-items: center;
                &-search {
                    margin-left: 50px;
                    &-icon {
                        i {
                            cursor: pointer;
                            position: relative;
                            z-index: 9;
                            &::before {
                                display: block;
                                color: var(--white);
                            }
                        }
                        &.two {
                            i::before {
                                color: var(--heading-color);
                            }
                        }
                        &.three {
                            i::before {
                                color: var(--white);
                            }
                        }
                    }
                    &-box {
                        position: fixed;
                        bottom: 0;
                        left: 50%;
                        width: 100%;
                        left: 0;
                        right: 0;
                        height: 0;
                        background: var(--heading-color);
                        z-index: 999;
                        transition: .5s;
                        overflow: hidden;
                        form {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 55%;
                        }
                        input {
                            background: var(--white);
                            color: var(--heading-color);
                            border: 0;
                        }
                        button {
                            position: absolute;
                            right: 15px;
                            top: 19px;
                            background-color: transparent;
                            text-align: center;
                            font-size: 22px;
                            color: var(--primary-color);
                            border: 0;
                            outline: none;
                            padding: 0;
                            margin: 0;
                        }
                        &.active {
                            height: 100%;
                            top: 0;
                        }
                        &-icon {
                            position: absolute;
                            right: 50px;
                            top: 50px;
                            font-size: 22px;
                            color: var(--white);
                            cursor: pointer;				
                            transform: rotate(0deg);
                            &:hover {
                                animation: rotate 0.4s ease 0s;			
                            }
                            i {
                                cursor: pointer;
                                position: relative;
                                z-index: 9;
                                &::before {
                                    display: block;
                                }
                            }
                        }
                    }
                }
                &-btn {
                    margin-left: 30px;
                    .theme-btn {
                        &::before {
                            background: var(--white);
                        }
                        &::after {
                            background: var(--white);
                        }
                        &:hover {
                            color: var(--heading-color);
                        }
                    }
                }
                &-sidebar {
                    &-popup {
                        &-contact {
                            &-item {
                                display: flex;
                                margin-bottom: 25px;
                                gap: 25px;
                                &-icon {
                                    margin-top: 8px;
                                    width: 30px;
                                    i {
                                        color: var(--primary-color);
                                        font-size: 30px;                
                                    }
                                }
                                &-content {
                                    span {
                                        color: var(--color-1);
                                        display: inline-block;
                                        margin-bottom: 5px;
                                    }
                                    h6 {
                                        max-width: 240px;
                                        a {
                                            transition: all .4s ease-out 0s;
                                            &:hover {
                                                color: var(--primary-color);
                                            }
                                        }
                                    }
                                }
                                &:last-child {
                                    margin: 0;
                                }
                            }
                        }
                        &-image {
                            margin: 40px 0;
                        }
                        &-social {
                            margin-top: 40px;
                            ul {
                                padding: 0;
                                margin: 0;
                                li {
                                    list-style: none;
                                    display: inline-block;
                                    margin-right: 10px;
                                    &:last-child {
                                        margin: 0;
                                    }
                                    a {
                                        i {
                                            display: inline-block;
                                            width: 40px;
                                            height: 40px;
                                            line-height: 40px;
                                            text-align: center;
                                            border: 1px solid var(--color-10);
                                            border-radius: 50%;
                                            color: var(--color-8);
                                            transition: all 0.4s ease-out 0s;     
                                            &:hover {
                                                color: var(--white);
                                                background: var(--primary-color);
                                                border-color: var(--primary-color);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.three {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
    &.two {
        padding: 40px 50px;
        border: 0;
        &::after {
            position: absolute;
            content: '';
            bottom: 0;
            width: 100%;
            height: 1px;
            background: var(--white);
            opacity: 0.15;
            left: 0;
        }
        .header__area-menubar-right {
            display: flex;
            align-items: center;
            &-info {
                margin-right: 80px;
                span {
                    i {
                        color: var(--primary-color); 
                        padding-right: 60px;                   
                    }
                    a {
                        position: relative;
                        color: var(--white);
                        transition: all 0.4s ease-out 0s;
                        &:hover {
                            color: var(--primary-color);
                        }
                        &::after {
                            position: absolute;
                            content: '';
                            width: 40px;
                            height: 2px;
                            left: 20px;
                            bottom: 8px; 
                            background: var(--primary-color);                       
                        }
                    }
                }
            }
        }
    }
    &.page {
        border: 0;
        &::after {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            width: 100%;
            height: 1px;
            background: var(--white);
            opacity: 0.1;
        }         
    }
    &.three {
        border: 0;
    }
}
/*==========================================================================
Menu Box CSS
==========================================================================*/
.header__area-menubar-right-sidebar-popup {
    position: fixed;
    width: 460px;
    height: 100%;
    right: 0;
    overflow: auto;
    transform: translateX(100%);
    top: 0;
    background: var(--heading-color);
    opacity: 0;
    visibility: hidden;
    z-index: 999999;
    transition: all 0.3s ease 0s;
    padding: 40px;
    scrollbar-width: none;
    &.three {
        background: var(--white);
    }
    &.two {
        background: var(--white);
    }
    &::-webkit-scrollbar {
        display: none;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateX(0%);
        right: 0;
    }
    &-icon {
        i {
            cursor: pointer;
            font-size: 30px;
            color: var(--white);
        }
        &.three {
            padding-left: 110px;
            margin-left: 30px;
            border-left: 1px solid var(--border-color-2);
            i {
                color: var(--primary-color);
            }            
        }
    }
    .sidebar-close-btn {
        position: absolute;
        top: 40px;
        right: 40px;
        transform: rotate(0);
        i {
            &::before {
                background: var(--primary-color);
                width: 40px;
                color: var(--white);
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 50%;
                cursor: pointer;
                display: block;
            }
        }
		&:hover {
			animation: rotate 0.4s ease 0s;			
		}
    }
    &-logo {
        margin-bottom: 30px;
        a {
            display: inline-block;
            img {
                max-width: 150px;
                position: relative;
                z-index: 999;
            }
        }
    }
    &-menu {
        width: 100%;
        height: auto;
        max-height: 75%;
        overflow-x: hidden;
        scrollbar-width: none;
        overflow-y: auto;
        margin-top: 50px;
        padding-bottom: 60px;
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                a {
                    padding: 15px 0;
                    display: block;
                    font-size: 24px;
                    line-height: 34px;
                    font-weight: 600;
                    font-family: var(--heading-font);
                    transition: all 0.4s ease;
                }
                i {
                    float: right;
                    font-size: 25px;
                    position: relative;
                    top: 5px;                    
                }
                &:hover > a {
                    color: var(--primary-color);
                }
                & .sub-menu {
                    padding-left: 15px;
                    li {
                        a {
                            font-size: 16px;
                            line-height: 26px;
                            font-weight: 500;     
                            padding: 5px 0;                       
                        }
                    }
                }
            }
        }
    }
    &-social {
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                display: inline-block;
                margin-right: 10px;
                &:last-child {
                    margin: 0;
                }
                a {
                    i {
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: var(--color-4);
                        border-radius: 50%;
                        color: var(--heading-color);
                        transition: all 0.4s ease-out 0s;     
                        &:hover {
                            color: var(--white);
                            border-color: var(--primary-color);
                            background: var(--primary-color);
                        }
                    }
                }
            }
        }
    }
    &-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 40px 40px 40px;
        width: 100%;
        background: var(--white);
        p {
            margin-bottom: 30px;
            a {
                color: var(--primary-color);
                font-weight: 500;
            }
        }
    } 
}
.sidebar-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
	opacity: 0.85;
    background: var(--color-6);
	display: none;
	transition: all 0.4s ease-out 0s;
    &.show {
        display: block;
    }
}
/*==========================================================================
Responsive Menu Css
========================================================================== */
.responsive-menu {
	position: absolute;
	top: 7px;
	right: 0;
	width: 100%;
}
.mean-container {
	a.meanmenu-reveal {
        color: var(--heading-color);
        width: 25px;
        height: 31px;
        top: 24px;
        padding: 0;
        transition: all 0s ease-out 0s;
		span {	
			background: var(--white);
			margin-top: 6px;		
		}
	}
	.mean-bar {
		background: transparent;
		padding: 0;
		z-index: 99;
		a i {
			font-size: 32px;
			display: block;
            color: var(--white);
		}
	}
	.mean-nav {
        background: var(--white);
        margin-top: 86px;
        box-shadow: var(--box-shadow-3);
		li:first-child > a {
			border: none;
		}
		ul li a.mean-expand {
			background: var(--primary-color);
			height: 34px;
			color: var(--white);
			&:hover {
			background: var(--primary-color);
			}
		}        
		.sub-menu li a.mean-expand {
			background: var(--primary-color);
			height: 34px;
			color: var(--white);
			&:hover {
			    background: var(--primary-color);
			}
		}
        ul li a i {
            display: none;
        }
	}
}
.mean-container .mean-nav ul li a,
.mean-container .mean-nav ul li li a,
.mean-container .mean-nav li li:first-child > a {
	color: var(--heading-color);
	font-size: 16px;
	line-height: 26px;
	font-weight: 600;
	text-transform: capitalize;
	border-top: 1px solid var(--color-5);
	opacity: 1;
}

@media (max-width: 1199px) {
    .header__area-menubar-right-sidebar-popup {
        &-icon {
            &.three {
                padding-left: 30px;          
            }
        }
    }
}
@media (max-width: 1100px) {
    .header__area {
        &-menubar {
            &-right {
                &-box {
                    display: none;
                }
            }
        }
    }
}
@media (max-width: 991px) {
    .header__area {
        padding: 0 40px;
        &-menubar {
            display: initial;
            &-left {
                &-logo {
                    position: relative;
                    padding: 28px 0;
                }
            }
            &.three {
                padding: 0;
                .header__area-menubar-left {
                    background: var(--white);
                    &-logo > a {
                        padding-left: 15px;
                    }
                    .mean-container {
                        a.meanmenu-reveal {
                            padding-right: 15px;
                            span {
                                background: var(--heading-color);
                            }
                        }
                        .mean-bar a i {
                            color: var(--heading-color);
                        }
                    }
                }
            }
        }
        &.two {
            padding: 0 40px;
            .header__area-menubar {
                display: flex;
                &-right-info {
                    margin-right: 40px;
                }
            }            
        }
    }
}
@media (max-width: 767px) {
    .header__area {
        &.two {
            .header__area-menubar {
                &-right-info {
                    display: none;
                }
            }            
        }
    }
}
@media (max-width: 575px) {
    .header__area {
        padding: 0;
        &.two {
            padding: 0;           
        }
        &-menubar-right-sidebar-popup {
            padding: 40px 30px;
            width: 100%;
            .sidebar-close-btn {
                right: 30px;
            }
            &-menu {
                margin-top: 20px;
                ul {
                    li {
                        a {
                            padding: 12px 0;
                            font-size: 22px;
                            line-height: 32px;
                        }
                        i {
                            top: 4px;                    
                        }
                    }
                }
            } 
            &-bottom {
                padding: 0 30px 40px 30px;
            } 
        }
    }
}