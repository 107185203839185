@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.page-container {
  width: 100%;
  height: 100vh;
}

.page-container h3 {
  font-family: 'Inconsolata', monospace;
  width: fit-content;
  height: 1.388rem;
  margin-left: 4.2777rem;
  padding-top: 2.22rem;
  text-align: center;
  font-size: 1.333rem;
  font-weight: 700;
  color: #333;
}

.page-content {
  display: flex;
  align-items: center;
  padding: 0 4.27777rem;
  height: calc(100vh - 3.611rem);
  font-family: 'Space Mono', monospace;
}

.image-container {
  flex: 0 0 29.957rem;
  height: 23.014rem;
  /*position: relative;
  top: 11.111rem;*/
  margin-right: 6.599rem;
}

.image-container img {
  object-fit: contain;
  width: 29.957rem;
  height: 23.014rem;
}

.page-details {
  /*position: relative;
  top: 11.11rem;*/
  display: flex;
  flex-direction: column;
  flex: 0 1 32.556rem;
  height: fit-content;
}

.page-details .page-title {
  font-size: 3.556rem;
  line-height: 5.2778rem;
  letter-spacing: -.035em;
  /*height: 6.611rem;*/
  margin-bottom: 2rem;
}

.page-details .page-description {
  width: 21.667rem;
  font-size: 1.333rem;
  margin-bottom: 3.556rem;
  line-height: 2rem;
}

.page-details button {
  width: 12rem;
  height: 3.778rem;
  font-size: .778rem;
  color: white;
  background-color: black;
}

.credit {
  color: #BDBDBD;
  text-align: center;
  font-size: .7778rem;
  line-height: .9444rem;
  position: absolute;
  bottom: 1.333rem;
  width: 10rem;
  text-align: center;
  margin: 0 calc((100vw - 10rem) / 2);
}

.credit span {
  font-weight: bold;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 15px;
  }

  .page-container h3 {
    padding-top: 1.111rem;
    margin-left: 1.333rem;
  }

  .page-content {
    flex-direction: column;
    height: calc(100vh - 2.5rem);
  }

  .image-container {
    padding-top: 3.556rem;
    flex: 1 1 15.935rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12.243rem;
    margin: auto;
  }

  .image-container img {
    flex: 1 1;
    width: 15.935rem;
    height: 12.243rem;
  }

  .page-details .page-title {
    flex: 0 1;
    font-size: 2.667rem;
    line-height: 3.944rem;
  }

  .page-details .page-description {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 3.667rem;
  }
}