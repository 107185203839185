/*==========================================================================
Team
==========================================================================*/
.team__area {
    &-title {
        text-align: center;
    }
    &-item {
        &-image {
            position: relative;
            &::after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                width: 100%;
                height: 40%;
                background: var(--heading-color);
                opacity: 0;
                z-index: 0;
                transition: all 0.4s ease-out 0s;    
            }
            &-overlay {
                position: absolute;
                bottom: 25px;
                left: 0;
                width: 100%;
                text-align: center;
                z-index: 1;
                &-social {
                    margin-bottom: 45px;
                    transition: all 0.4s ease-out 0s;    
                    opacity: 0;
                    ul {
                        padding: 0;
                        margin: 0;
                        li {
                            list-style: none;
                            display: inline-block;
                            margin-right: 10px;
                            &:last-child {
                                margin: 0;
                            }
                            a {
                                i {
                                    display: inline-block;
                                    width: 40px;
                                    height: 40px;
                                    line-height: 40px;
                                    text-align: center;
                                    border: 1px solid var(--color-1);
                                    border-radius: 50%;
                                    color: var(--color-9);
                                    transition: all 0.4s ease-out 0s;     
                                    &:hover {
                                        color: var(--white);
                                        border-color: var(--primary-color);
                                        background: var(--primary-color);
                                    }
                                }
                            }
                        }
                    }
                } 
                &-content {
                    background: var(--white);
                    padding: 30px;
                    text-align: center;
                    margin: 0 25px;
                    span {
                        font-weight: 500;
                        font-family: var(--heading-font);
                        color: var(--primary-color);
                    }
                }               
            }
        }
        &:hover {
            .team__area-item-image {
                &::after {
                    height: 100%;
                    opacity: 0.8;
                }
                &-overlay {
                    &-social {
                        opacity: 1;
                        margin-bottom: 25px;
                    }
                }
            }
        }
    }
}
/*==========================================================================
Services Team
==========================================================================*/
.services__team {
    &-item {
        &-image {
            position: relative;
            &::after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 40%;
                background: var(--heading-color);
                opacity: 0;
                z-index: 0;
                transition: all 0.4s ease-out 0s;    
            }
            &-content {
                background: var(--white);
                padding: 30px 0;
                text-align: center;
                position: absolute;
                bottom: 25px;
                left: 25px;
                right: 25px;
                z-index: 2;
                span {
                    font-weight: 500;
                    font-family: var(--heading-font);
                    color: var(--primary-color);
                }
                &-social {
                    transition: all 0.4s ease-out 0s;
                    margin-top: -45px;
                    opacity: 0;
                    ul {
                        padding: 0;
                        margin: 0;
                        li {
                            list-style: none;
                            display: inline-block;
                            margin-right: 10px;
                            &:last-child {
                                margin: 0;
                            }
                            a {
                                i {
                                    display: inline-block;
                                    width: 40px;
                                    height: 40px;
                                    line-height: 40px;
                                    text-align: center;
                                    border: 1px solid var(--color-12);
                                    border-radius: 50%;
                                    color: var(--color-9);
                                    transition: all 0.4s ease-out 0s;     
                                    &:hover {
                                        color: var(--white);
                                        border-color: var(--primary-color);
                                        background: var(--primary-color);
                                    }
                                }
                            }
                        }
                    }
                } 
            } 
        }
        &:hover {
            .services__team-item-image {
                &::after {
                    height: 100%;
                    opacity: 0.8;
                }
                &-content-social {
                    margin-top: 10px;
                    opacity: 1;
                }
            }
        }
    }
}